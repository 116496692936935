// NAVBAR

$nav-font-size: 1rem;
$nav-font-size-lg: 0.8rem;

.navbar-container {
  width: 100%;
  // height: calc(100vh - #{$header-height});
  position: absolute;
  // top: $header-height;
  // height: var(--height);
  // padding-bottom: $header-height;

  // height: calc(100vh - #{$header-height});
  // Only target safari for 100vh bug fix
  // @supports (-webkit-touch-callout: none) {
  //   height: -webkit-fill-available; // works for iOS 14.1 but not for iOS 15.1 :(
  // }

  @media (min-width: $bp-lg) {
    width: navbar-width($bp-lg);
    // height: calc(100vh - #{$header-height-lg});
    // top: $header-height-lg;
  }

  @media (min-width: $bp-xl) {
    width: navbar-width($bp-xl);
    // height: calc(100vh - #{$header-height-xl});
    // top: $header-height-xl;
  }

  @media (min-width: $bp-xxl) {
    width: navbar-width($bp-xxl);
  }

  /* ========================================
     MOBILE OVERLAY
  ======================================== */
  .mobile-overlay {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;
  }

  /* ========================================
     NAVBAR
  ======================================== */
  .navbar {
    width: navbar-width();
    height: 100%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    // padding: 20px 0 env(safe-area-inset-bottom) 0;
    padding: 20px 0 calc(100px + env(safe-area-inset-bottom)) 0;
    // position: absolute;
    // top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fff;
    background-color: $light-grey;
    border-right: 1px solid lighten($border-colour, 5%);
    transform: translateX(0);
    transition: transform 500ms ease;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.03);

    @media (min-width: $bp-lg) {
      width: navbar-width($bp-lg);
    }
  
    @media (min-width: $bp-xl) {
      width: navbar-width($bp-xl);
    }
  
    @media (min-width: $bp-xxl) {
      width: navbar-width($bp-xxl);
    }
  
    ul {
      width: 100%;
      margin: 0;
      padding: 0;
  
      .nav-item {
        display: flex;
        list-style: none;
        font-size: $nav-font-size;
        font-weight: 400;

        @media (min-width: $bp-lg) {
          font-size: $nav-font-size-lg;
        }
  
        .nav-link {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 0.25em 1.25em;
          color: $dark-font-colour;
  
          @media (min-width: $bp-xxl) {
            padding: 0.25em 2em;
          }

          .nav-item__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5em;
            border-radius: 50%;
            background: transparent;

            svg {
              width: $nav-font-size;
              height: $nav-font-size;

              @media (min-width: $bp-lg) {
                width: $nav-font-size-lg;
                height: $nav-font-size-lg;
              }
            }
          }

          .nav-item__label {
            margin-left: 1em;
            display: block;
          }

          .nav-item__indicator {
            width: 1rem;
            height: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            border-radius: 50%;
            font-size: 0.75em;
            font-weight: 500;
            color: $pdfIconColour;
            background-color: rgba($pdfIconColour, 0.1);
          }
  
          &.active {
            color: $secondary-colour;

            .nav-item__icon {
              background-color: rgba($secondary-colour, 0.2); 
            }
          }

          &:hover {
            color: $secondary-colour;
          }
        }
  
      }
    }

  }

  /* ========================================
     NAVIGATION CONTAINER: EXPANDED
  ======================================== */
  &.expanded {
    // Only show on mobile
    @media (max-width: 991px) {
      .mobile-overlay {
        opacity: 1;
        visibility: visible;
        transition: opacity 500ms ease;
      }
    }

  }

  /* ========================================
     NAVIGATION CONTAINER: COLLAPSED
  ======================================== */
  &.collapsed {
    width: 0;

    .navbar {
      transform: translateX(-100%);
      transition: transform 500ms ease;
    }
  }

  @include custom-scrollbar();
  
}