// ACCOUNT

.account {

  .user-image {
    width: 100px;
    height: 100px;
    margin-top: 2rem;

    svg {
      width: 50px;
      height: auto;
    }

    @media (min-width: $bp-lg) {
      width: 150px;
      height: 150px;
      margin-top: 3rem;
    }
  }

  .greeting {
    margin-top: 1em;
  }

  .username {
    font-size: 1.125rem;

    @media (min-width: $bp-lg) {
      font-size: 1rem;
    }
  }

  .actions {
    .edit-profile-link {
      margin-top: 2rem;
      color: $secondary-colour;

      &:hover {
        text-decoration: none;
      }

      .icon {
        margin-right: 5px;
      }
    }
  }

  .creators {
    margin-top: 4rem;
    text-align: center;
  }
}