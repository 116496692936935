// AUTH / LOGIN

.login {
  .form-group {
    .icon {
      width: 1rem;
      height: auto;
      margin-right: 0.5rem;
      color: $primary-colour;
    }

    label, 
    input {
      line-height: 1.5;

      @media (min-width: $bp-lg) {
        font-size: 0.8rem;
      }

    }

    label {
      margin-bottom: 0;
      font-weight: 400;
      color: $primary-colour;
    }

    input {
      &::placeholder {
        color: $normal-font-colour;
      }
    }

  }
}