// CONVERSATION (Message thread)

$message-received-colour: #efefef;
$message-sent-colour: $primary-colour;
$message-border-radius: 1rem;

.conversation {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$header-height});
  padding: 0; // safari for 100vh bug fix
  @include custom-scrollbar();

  // Only target safari for 100vh bug fix
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  @media (min-width: $bp-md) {
    padding-top: $page-margin;
  }

  @media (min-width: $bp-lg) {
    height: calc(100vh - #{$header-height-lg});
  }

  @media (min-width: $bp-xl) {
    height: calc(100vh - #{$header-height-xl});
  }

  /* Conversation header
  ======================================== */
  .conversation__header {
    padding-top: ($page-margin / 1.25); // same as default screen.scss
    padding-bottom: 0.25rem;
    border-bottom: 1px solid $border-colour;

    .page-header {
      margin-bottom: 0;
      h1 {
        font-size: 1rem;
      }
    }

    .last-message {
      text-align: right;
      font-size: 0.8rem;
      line-height: 1.2;
      color: $light-font-colour;

      time {
        margin-left: 0.5em;
        font-weight: 400;
      }
    }
  }

  .conversation-block {
    flex: 1;
    padding-top: 2rem;
    padding-right: 15px; // scrollbar spacing
    overflow: auto;
  }

  .load-more-end-text {
    margin-bottom: 1rem;
    font-size: 0.8rem;
    text-align: center;
    color: $light-font-colour;
  }

  .message-block__timestamp {
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 0.7rem;
    color: $light-font-colour;
  }

  /* Message
  ======================================== */
  .message {
    display: flex;
    width: auto;
    max-width: 75%;
    margin-bottom: 1rem;

    .user-image {
      width: 50px;
      height: 50px;
    }

    .message__author, 
    .message__body {
      font-size: 0.9rem;
    }

    .message__author {
      margin-bottom: 0.25em;
    }

    .message__body {
      padding: 0.5rem 1rem;
      border-radius: $message-border-radius;
    }

    .message__timestamp {
      margin-left: auto;
      font-size: 0.7rem;
      color: $light-font-colour;
    }

  /* Message: Received
  ======================================== */
    &.message--received {
      .message__body {
        background-color: $message-received-colour;
      }
    }

  /* Message: Sent
  ======================================== */
    &.message--sent {
      margin-left: auto;
      justify-content: flex-end;

      .message-content-wrapper {
        margin-left: auto;
      }

      .message__body {
        margin-left: auto;
        color: #fff;
        background-color: $message-sent-colour;
      }
    }
  }

}