// presentation

.presentations {

  .presentation-item {
    min-height: 135px;
    display: flex;
    flex-direction: column;

    @media (min-width: $bp-sm) {
      min-height: initial;
    }

    @media (min-width: $bp-lg) {
      margin-bottom: 50px;
    }

    .presentation-item__content {
      @media (min-width: $bp-sm) {
        padding-left: 0;
      }

      .title {
        @media (min-width: $bp-sm) {
          margin: 0.75rem 0; 
        }
      }

      .excerpt {
        @include line-clamp(2);
      }

    }

    .presentation-item__footer {
      // Flick height = 75%;
      @include border-flick();
    }

  }

}