// Bootstrap customisation

$theme-colors: (
  "primary": #685e9d,
  "secondary": #b6519a,
  "gradient-pink": #f0446a,
  "gradient-orange": #f9690e,
  "border-colour": #d9d9d9, 
);

// Global 'pill' border radius for buttons
// $btn-border-radius: (0.375rem * 2 ) + (1rem * 1.5);
// $btn-padding-x: 1.5rem;
$btn-border-radius: 0.375rem;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";