// PAGE HEADER

$border-width: 1px;

.page-header {
  margin-bottom: $page-margin;

  .page-header__filters {
    display: flex;
    align-items: center;

    @media (min-width: $bp-xl) {
      font-size: 0.8em;
    }

    .category-selector {
      width: 100%;
      z-index: 999; // ensure on highest layer
      // padding: 5px;
      // border: 1px solid $normal-font-colour;
      // border-radius: 3px;
      // color: $normal-font-colour;
      // background-color: #fff;
    }

    .filter-btn {
      @include btn-reset();
      display: flex;
      align-items: center;
      padding: 0 0.25rem 0.25rem 0.25rem;
      border-bottom: $border-width solid transparent;

      svg {
        margin-right: 0.5em;
      }

      &:nth-of-type(1) {
        color: $primary-colour;

        &.active {
          border-bottom: $border-width solid $primary-colour;
        }

        @media (min-width: $bp-md) {
          margin-left: 30px; // same as col gutter
        }
      }

      &:nth-of-type(2) {
        margin-left: 30px; // same as col gutter
        color: $secondary-colour;

        &.active {
          border-bottom: $border-width solid $secondary-colour;
        }
      }

      &:nth-of-type(3) {
        margin-left: 30px; // same as col gutter
        color: $accent-colour;

        &.active {
          border-bottom: $border-width solid $accent-colour;
        }
      }

    }

  }

}