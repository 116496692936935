// GLOBAL FORM STYLES

$form-base-font-size: 1rem;
$form-base-font-size-lg: 0.8rem;

// Limit width on these screens only
.add-notice,
.edit-notice,
.add-noticeboard,
.add-gallery,
.edit-gallery {
  max-width: 600px;
  margin: 0 auto;
}

/* Forum group
======================================== */
.form-group {
  margin-bottom: 1.5rem;
  font-size: $form-base-font-size;

  @media (min-width: $bp-lg) {
    font-size: $form-base-font-size-lg;
  }

  /* Elements
  ======================================== */
  p,
  label, 
  input,
  .form-control {
    font-size: inherit;
    line-height: 1.5;
  }

  label {
    margin-bottom: 0;
    font-weight: 400;

    &.label-checkbox {
      color: inherit;
      font-weight: inherit;
    }
  }

  input {
    &::placeholder {
      color: $normal-font-colour;
    }
  }

  button {
    display: flex;
    align-items: center;
    font-size: inherit;

    svg {
      width: $form-base-font-size;
      height: auto;
      margin-right: 0.25rem;
      color: inherit;
    }

    // Link style buttons
    &.btn-link {
      @include btn-reset();
      padding: 0;
      color: $secondary-colour;

      &:hover {
        color: darken($secondary-colour, 10%);
      }
    }
  }

  .form-control {
    font-weight: inherit;
    color: inherit;
  }

  /* Errors
  ======================================== */
  // MARK: Are we using this ".error"? Test this
  .error {
    @include verticallyCenter();
  
    svg {
      color: $pdfIconColour;
      margin-right: 2px;
    }
  }

  .error-message {
    padding: 0.5rem 0.75rem;

    span {
      display: block;
    }
  }

  /* Spinner
  ======================================== */
  .spinner-border {
    width: $form-base-font-size-lg;
    height: $form-base-font-size-lg;
    border-width: 2px;
  }

  /* Category selector
  ======================================== */
  .category-selector {
    max-width: 390px;
    font-size: inherit;
  }

  /* ck-editor
  ======================================== */
  .ck-editor {
    .ck-focused {
      border-color: $input-focus-border-color;
      box-shadow: inset $input-focus-box-shadow;
    }
  }

  /* User search 
      -> Create & Edit group modal
  ======================================== */
  .users-container {
    button {
      &.user {
        // Do not use => @include btn-reset();
        // Reset
        padding: 0;
        background-color: transparent;
        border: none;
        outline: none;
        border-radius: 0;
        // End reset
        width: 100%;
        padding: 0.5rem 0.75rem;
        font-weight: inherit;
        border-bottom: 1px solid $border-colour;
        color: inherit;

        &:hover {
          background-color: rgba($primary-colour, 0.05); 
        }

        &.selected-user {
          color: $primary-colour;
          background-color: rgba($primary-colour, 0.2);

          &:hover {
            background-color: rgba($primary-colour, 0.25); 
          }
        }
      }
    }
  }

  /* Members results 
      -> Create & Edit notice
      -> Create & Edit group modal
  ======================================== */
  .members-container {
    .member {
      padding: 0.5rem 0.75rem;
      font-weight: inherit;
      border-bottom: 1px solid $border-colour;

      button {
        svg {
          margin: 0;
        }
      }
    }
  }

  /* Documents
      -> Create & Edit notice
  ======================================== */
  .documents-container {

    .document {
      padding: 0.5rem 0.75rem;
      border-bottom: 1px solid $border-colour;
      color: $primary-colour;

      svg {
        width: $form-base-font-size;
        height: auto;
        color: inherit;
      }
    }

    .file-name {
      padding: 0 0.5rem;
    }
  }

  /* Action buttons
  ======================================== */
  .submit-btn {
    svg {
      margin: 0 0 0 0.25rem;
    }
  }

}
