// COURSE ITEM

.courses {
  .course-item {
    .submitted-flag {
      font-size: 0.8rem;
      color: $green;

      span {
        margin-left: 0.5rem;
      }
    }
  }
}
