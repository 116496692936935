// Theme colours
$primary-colour: #685e9d;
$secondary-colour: #b6519a;
$accent-colour: #f9690e;
$ca-blue: #375b7b;

// Gradient colours
$gradient-blue: $ca-blue;
$gradient-purple: $primary-colour;
$gradient-light-purple: $secondary-colour;
$gradient-pink: #f0446a;
$gradient-orange: $accent-colour;

// Gradient
$gradient-colours: $gradient-blue -10%, $gradient-purple, $gradient-light-purple,
  $gradient-pink, $gradient-orange 105%;

// Font colours
$light-font-colour: #999;
$normal-font-colour: #777;
$dark-font-colour: #333;

// Utility colours
$link-colour: $accent-colour;
// $border-colour: #ebeef0;
$border-colour: #d9d9d9;
$light-grey: #fafafa;
$scrollbar-colour: #d8d8d8;
$new-content-indicator-bg-colour: #f7e6e6;

// Icon colours
$pdfIconColour: #d32f2f;
$greenIconColour: #388E3C;
$twitter-blue: rgb(29, 155, 240);
$facebook-blue: #1877f2;

// Breakpoint sizes
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1440px;
$bp-xxxl: 1920px;

// Max size of app content
$content-container-size: 1200px;
// $container-xxl-size: 1440px;

// All pages margin top
$page-margin: 2rem;

// Navbar sizes
// $navbar-width: 183px;
// $navbar-width-lg: 221px; // 206px + 15px (scrollbar width)
// $navbar-width-xl: 244px; // 229px + 15px (scrollbar width)
// $navbar-width-xxl: 284px; // 269px + 15px (scrollbar width)

// Navbar sizes: V2 with indicator
// $navbar-width: 250px;
$navbar-width: 320px;
$navbar-width-lg: 236px; // 221px + 15px (allow spacing for indicator)
$navbar-width-xl: 259px; // 244px + 15px (allow spacing for indicator)
$navbar-width-xxl: 290px; // 275px + 15px (allow spacing for indicator)

// Header sizes
$header-height: 46px;
$header-height-lg: 48px;
$header-height-xl: 54px;

// Reaction colours
$reactionLike: #1E90FF; // dodger blue
$reactionHeart: #DC143C; // crimson
$reactionSurprised: #FFA500; // orange
