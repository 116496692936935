// SINGLE PAGE DEFAULT
.single {
  padding: ($page-margin / 1.25) 0;

  @media (min-width: $bp-md) {
    padding: $page-margin 0;
  }

  .article {
    max-width: 920px;
    margin: 0 auto;

    h1 {
      margin-bottom: 0.75em;
    }

    .article__header {
      margin-bottom: 0.75em;

      .article__meta {
        color: $normal-font-colour;

        svg {
          width: 20px;
          height: 100%;
          margin-right: 0.25rem;
        }
      
        .label {
          font-size: 0.875rem;
        }

        .category {
          color: $primary-colour;
        }
  
        .meta__category {
          display: flex;
          align-items: center;
          position: relative;
        }
  
      }
    }

    .article__image {
      margin-bottom: 2em;
    }

    .article__body {
      h5 {
        color: $primary-colour;
      }
  
      h6 {
        color: $gradient-pink;
      }
  
      ul {
        list-style: none;
      
        li {
          &::before {
            width: 1em;
            display: inline-block;
            margin-left: -1em;
            content: "\2022";
            font-weight: bold;
            color: $primary-colour;
          }
        }
      }

      blockquote {
        padding: 1rem 1rem 0.001rem 1rem;
        color: $primary-colour;
        background-color: rgba($primary-colour, 0.2);
      }
    }

    .article__author {
      display: flex;
      align-items: center;
      margin-top: 2rem;
      padding-top: 1rem;
      border-top: 1px solid $border-colour;
      
      a {
        text-decoration: none;
      }

      .image {
        width: 30px;
        height: 30px;
      }

      .name {
        margin-left: 5px;
        font-size: 0.875rem;

        strong {
          color: $primary-colour;
          &:hover {
            color: $secondary-colour;
          }
        }
      }
    }

  }

}