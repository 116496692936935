// USER IMAGE

.user-image {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid lighten($border-colour, 5%);
  background-color: $light-grey;
  color: $normal-font-colour;
  overflow: hidden;

  svg {
    color: $normal-font-colour;
  }
}