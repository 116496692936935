// NEWS

.news {

  .news-item {
    min-height: 135px;
    display: flex;
    flex-direction: column;

    @media (min-width: $bp-sm) {
      min-height: initial;
    }

    @media (min-width: $bp-lg) {
      margin-bottom: 50px;
    }

    .news-item__content {
      @media (min-width: $bp-sm) {
        padding-left: 0;
      }

      .title {
        @media (min-width: $bp-sm) {
          margin: 0.75rem 0; 
        }
      }

      .excerpt {
        @include line-clamp(2);
      }

    }

    .news-item__footer {
      // Flick height = 75%;
      @include border-flick();
    }

    // STICKY ITEM
    &.sticky {

      .news-item__content {
        padding-left: 0;

        .title {
          margin: 0.75rem 0; 

          @media (min-width: $bp-sm) {
            margin-top: 0;
          }
          @media (min-width: $bp-lg) {
            font-size: 1.75rem;
          } 
          @media (min-width: $bp-xl) {
            font-size: 2rem;
          } 
        }

        .excerpt {
          @media (min-width: $bp-lg) {
            font-size: 1rem;
          } 

          @media (min-width: $bp-xxl) {
            @include line-clamp(4);
          }
        }
      }

    }

    // FEATURED ITEM
    &.featured {
      position: relative;

      .news-item__image {
        width: 100%;
        height: 100%;
        position: relative;

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background: linear-gradient(0deg, #000, transparent);
        }

      }

      .news-item__content {
        width: 100%;
        height: auto;
        position: absolute;
        bottom: 0;
        padding: 0 0.5rem;
        
        a {
          display: block;
          text-decoration: none;
          color: #fff;
        }

        .title {
          @media (min-width: $bp-lg) {
            font-size: 1.25rem;
          }
        }

        .news-item__footer {
          padding-right: 0;
          border-bottom: initial;
          border-right: initial;
          border-bottom-right-radius: initial;
  
          &::after {
            content: none;
          }

          .timestamp {
            color: #fff;
          }

        }
        
      }

      .favourite-btn {
        color: #fff;

        &.filled {
          svg {
            fill: #fff;
          }
        }
      }

    }

    // NO IMAGE
    &.no-image {
      background-color: rgba($primary-colour, 0.07);

      .news-item__content {
        width: 100%;
        padding: 0 0.5rem;

        .title {
          margin: 0.75rem 0;
        }

        .excerpt {
          @media (min-width: $bp-lg) {
            @include line-clamp(6);
          }
        }
      }

      .news-item__footer  {
        border-right: initial;
        border-bottom-right-radius: initial;

        &::after {
          content: none;
        }

        .timestamp {
          padding-left: 0.5rem;
        }
      }

    }

    // HOVERED STATE
    &.hovered {

      // FEATURED ITEM
      &.featured {
        .title {
          color: #fff;
        }
      }
      
    }

  }

}