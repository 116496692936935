// HEADER

$dropdown-font-size: 1rem; /* same as nav */
$dropdown-font-size-lg: 0.8rem; /* same as nav */

$user-image-size: 30px;

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  // padding: 10px 0;
  // box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.03);
  // border-bottom: 1px solid $border-colour;
  @include gradient();

  .navbar-btn {
    @include btn-reset();

    svg {
      color: #fff;
    }

  }

  .branding {
    width: 150px;
    display: block;
    margin: 0 auto;

    @media (min-width: $bp-sm) {
      margin: 0;
    }
  }

  .search {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    border-radius: 5px;
    font-size: 0.8rem;
    color: $normal-font-colour;
    background: #fff;
    border: 1px solid transparent;
    transition: width 500ms ease;

    input {
      width: 100%;
      padding: 0.25em 0.5em;
      border: none;
      border-radius: 5px;
      outline: none;
    }

    button {
      @include btn-reset();
      padding: 0.25em 0.5em;

      svg {
        width: 0.8rem;
        height: auto;
        color: $primary-colour;
        transition: color 250ms ease;
      }

      &:hover {
        svg {
          color: $accent-colour;
          transition: color 250ms ease;
        }
      }
    }

    &:focus-within {
      width: 100%;
      border: 1px solid $primary-colour;
      transition: width 500ms ease;
    }

  }

  .login-button {
    height: $user-image-size;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;

    svg {
      margin-right: 0.5rem;
      border-radius: 50%;
    }
  }

  .user {
    display: flex;
    align-items: center;

    &.dropdown-toggle {
      @include btn-reset();

      &::after {
        content: none; // hide default caret icon
      }
    }

    .user__image {
      width: $user-image-size;
      height: $user-image-size;
      border: 1px solid #fff;
      position: relative;
    }

    .user__name {
      margin: 0 1em;
      font-size: 0.8rem;
      // color: $normal-font-colour;
      color: #fff;
    }

    .dropdown-main__indicator {
      position: absolute;
      top: 0;
      left: (30px - 7px); // image width - width / 2
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      border-radius: 50%;
      font-size: 0.5em;
      font-weight: 500;
      color: $pdfIconColour;
      background-color: rgba(255, 255, 255, 0.9);
    }

    svg {
      width: 1rem;
      height: auto;
      // color: $normal-font-colour;

      &.user__toggle {
        color: #fff;
      }
    }
  }

  /* ========================================
     DROPDOWN
  ======================================== */
  .dropdown {

    .dropdown-menu {
      min-width: 11rem; // increase default bootstrap size
      font-size: $dropdown-font-size;

      @media (min-width: $bp-lg) {
        min-width: 10rem; // restore default bootstrap size
        font-size: $dropdown-font-size-lg;
      }
    }

    .dropdown-item {
      display: flex;
      align-items: center;
      padding: 0.25em 1em;
      color: $dark-font-colour;

      .dropdown-item__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5em;
        border-radius: 50%;
        background: transparent;

        svg {
          width: $dropdown-font-size;
          height: $dropdown-font-size;

          @media (min-width: $bp-lg) {
            width: $dropdown-font-size-lg;
            height: $dropdown-font-size-lg;
          }
        }
      }

      .dropdown-item__label {
        margin-left: 0.5em;
        display: block;
      }

      &.active {
        color: $secondary-colour;
        background-color: transparent;

        .dropdown-item__icon {
          background-color: rgba($secondary-colour, 0.2); 
        }
      }

      &:hover,
      &:focus {
        color: $secondary-colour;
        background-color: transparent;
      }
    }

    .dropdown-item__indicator {
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      border-radius: 50%;
      font-size: 0.5em;
      font-weight: 500;
      color: $pdfIconColour;
      background-color: rgba($pdfIconColour, 0.1);
    }

  }
}